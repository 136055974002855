import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import SimpleModernHeadline from '../../components/SimpleModernHeadline/SimpleModernHeadline';
import HeadlineWithImage from '../../components/HeadlineWithImage/HeadlineWithImage';

import img_leistung_20 from '../../images/leistungen/img20.jpg';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO title="Mesotherapie | Dr. K. Kostka in Bochum" description="Dr. K. Kostka bietet Mesotherapien mitten in Bochum. Moderne Praxis ✓ Top Ausstattung ✓ Vereinbaren Sie jetzt einen Termin ✓"/>
    <Section>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={12}>
            <HeadlineWithImage headline={"Mesotherapie"} subtitle={"Behandlung über die Haut"} frontImage={img_leistung_20}></HeadlineWithImage>
          </Col>
        </Row>
        Die Mesotherapie vereint therapeutische Grundsätze der Akupunktur, Neuraltherapie und Arzneimitteltherapie.
        <br/>
        <br/>
        Diese schonende und sanfte Behandlungstechnik bringt kleinste Mengen an Wirkstoffen möglichst nahe an den Ort der Erkrankung.
        <br/>
        <br/>
        Eine spezielle Wirkstoffmischung aus Medikamenten, Vitaminen, Aminosäuren und Antioxidantien wird entweder als Depot lokal auf die Haut aufgetragen oder mit winzigen Nadelstichen in die oberste Hautschicht wenige Millimeter tief eingebracht.
        <br/>
        Aus diesem Hautdepot werden die Wirkstoffe nach und nach in die tieferliegenden erkrankten Strukturen transportiert. Für einen schnellen und gleichzeitig lang anhaltenden Effekt sind daher nur sehr geringe Wirkstoffmengen nötig.
        <br/>
        <br/>
        <SimpleModernHeadline h3={true}>Anwendungsbereiche:</SimpleModernHeadline>
        <ul>
          <li>Schmerzzustände bei orthopädischen Erkrankungen</li>
          <li>Sehnenreizungen</li>
          <li>Durchblutungs- und Wundheilungsstörungen</li>
          <li>Migräne und Spannungskopfschmerz</li>
        </ul>
      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;
